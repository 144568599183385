<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ item.name }}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div
            v-if="item.id == PROMOTION_2"
            class="col-2"
          >
            <div class="mb-1">
              <label
                for="name"
                class="form-label"
              >Promotion year</label>
              <v-select
                v-model="year"
                label="name"
                :clearable="false"
                :options="years"
                :get-option-key="option => option.value"
                @input="reloadData()"
              />
            </div>
          </div>
          <div
            v-if="loading"
            id="loading-bg"
          >
            <div
              class="loading"
            >
              <div class="effect-1 effects" />
              <div class="effect-2 effects" />
              <div class="effect-3 effects" />
            </div>
          </div>
          <div
            v-else
            class="col-12"
          >
            <div
              class="table-responsive"
            >
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th
                      v-for="header in item.headers"
                      :key="header"
                    >
                      {{ header }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="data, superindex in item.data"
                    :key="data"
                  >
                    <td
                      v-for="field, index in data"
                      :key="superindex + ' ' + index"
                    >
                      <span v-html="field" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  data() {
    return {
      PROMOTION_2: 44,
      year: { name: 2024, value: 2024 },
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      item: 'reports/item',
    }),
    years() {
      let year = 2005
      const endYear = 2026
      const years = []

      for (let i = year; i <= endYear; i++) {
        years.push({ name: year, value: year })
        year++
      }
      return years
    },
  },
  async mounted() {
    this.reloadData()

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async reloadData() {
      this.loading = true
      await this.$store.dispatch('reports/fetchReport', { id: this.$route.params.report_id, year: this.year.value })
      this.loading = false
    },
  },
}
</script>
